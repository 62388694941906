import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";
import AuthVerify from "./common/auth-verify";

import Container from 'react-bootstrap/Container';

import Login from "./components/login.component";
import Register from "./components/register.component";
import Profile from "./components/profile.component";
import PuzzleForm from './PuzzleForm';
import PuzzleList from './components/puzzle-list-component';
import ExistingPuzzle from './components/existing-puzzle.component';


class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;

    return (
      <div>
        <nav className="navbar navbar-expand navbar-dark bg-dark">
          <Link to={"/"} className="navbar-brand">

          </Link>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/home"} className="nav-link">
                Discover
              </Link>
            </li>
          </div>

          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/create"} className="nav-link">
                Create
              </Link>
            </li>
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/profile"} className="nav-link">
                  View Profile: {currentUser.username}
                </Link>
              </li>
              <li className="nav-item">
                <a href="/login" className="nav-link" onClick={this.logOut}>
                  Logout
                </a>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/register"} className="nav-link">
                  Sign Up
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Container className="p-3">
              <PuzzleList />
            </Container>} />
            <Route path="/home" element={<Container className="p-3">
              <PuzzleList />
            </Container>} />
            <Route path="/create" element={<Container className="p-3">
              <PuzzleForm />
            </Container>} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/puzzle/:puzzleId" element={<ExistingPuzzle/>}/>
            
          </Routes>

          <AuthVerify logOut={this.logOut}/>
        </div>
      </div>
    );
  }
}

export default App;