import React from "react";

import Container from "react-bootstrap/esm/Container";
import {Dna} from 'react-loader-spinner';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Link } from "react-router-dom";

class PuzzleList extends React.Component {
    constructor(props) {
        super();
        this.state = {
            loading: false,
            puzzleData: null
        };
    }


    componentDidMount() {
        this.loadPuzzles();
    }

    loadPuzzles = async () => {
        this.setState({loading: true});

        const response = await fetch('/api/fetchPuzzles', {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const data = await response.json();

        this.setState({loading: false, puzzleData: data});
    }

    renderPuzzles = () => {
        if(this.state.puzzleData) {
            const puzzles = this.state.puzzleData;
            console.log(puzzles);
            return puzzles.map(puzzle => {
                return(
                    <Col>
                        <Link to={"/puzzle/" + puzzle['imagekey']}>
                            <img width="100%" src={puzzle['imagepath']} />
                        </Link>
                        <div>
                            <Row>
                                <Col>
                                    <b>{puzzle['imagename']}</b>
                                </Col>
                            </Row>
                            <Row style={{display: puzzle['imageprompt'] ? 'block' : 'none'}}>
                                <Col >
                                    <b>Prompt: </b>{puzzle['imageprompt']}
                                </Col>
                            </Row>
                            <Row>
                                <b>Creator: {puzzle['username']}</b>
                            </Row>
                            <br/>
                        </div>
                    </Col>
                )
            });
        }
        //return(<div>puzzles</div>)
    }

    render() {
        return(
            <Container>
               <h1 className="header">Recent Puzzles</h1> 
               <Container style={{display: this.state.loading ? 'block' : 'none'}}>
                <br/>
                <h3>Loading...</h3>
                <Dna color="#00BFFF" height={80} width={80} />
               </Container>
               <Container style={{display: !this.state.loading ? 'block' : 'none'}}>
                    <Row xs={3}>
                        {this.renderPuzzles()}                  
                    </Row>
               </Container>
            </Container>
        );
    }
}

export default PuzzleList;