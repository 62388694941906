import React, { Component } from "react";

import { withRouter } from '../common/with-router';
import PuzzleField from "../PuzzleField";
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

class ExistingPuzzle extends Component {

    constructor(props) {
        super(props);
        this.puzzleKey = props.router.params.puzzleId;

        this.puzzleField = React.createRef();

        this.state = {
            preparing: true,
            imagePath: null,
            puzzleSize: 'Small'
        };
    }

    componentDidMount() {
        this.loadPuzzleImage();
    }

    loadPuzzleImage = async () => {
        const response = await fetch('/api/fetchImageByKey?imageKey=' + this.puzzleKey, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const data = await response.json();
        this.setState({imagePath: data.imagePath});


    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        this.puzzleField.current.initializeNewPuzzle({ imageUrl: this.state.imagePath, puzzleSize: this.state.puzzleSize, errorMessage: null, noShare: true });
        this.setState({preparing: false});
    }

    render() {
        return (
            <Container className="p-5 mb-4 bg-light rounded-3">
                <h1>Challenge an existing puzzle!</h1>
                <Container style={{ display: this.state.imagePath ? 'block' : 'none'}}>
                    <img src={this.state.imagePath} style={{maxWidth:"250px", maxHeight:"250px"}}/>
                    <br/>
                    <br/>
                </Container>
                <Form onSubmit={this.handleSubmit} style={{ display: this.state.preparing ? 'block' : 'none' }}>
                    <h3>Configure puzzle settings</h3>
                    <Form.Group className="mb-3" controlId="formPuzzleSize">
                        <Form.Label>Puzzle Size</Form.Label>
                        <Form.Select name="puzzleSize" onChange={this.handleChange}>
                            <option>Small</option>
                            <option>Medium</option>
                            <option>Large</option>
                        </Form.Select>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                            Generate Puzzle
                    </Button>
                </Form>
                <PuzzleField ref={this.puzzleField} />
            </Container>
        );
    }
}

export default withRouter(ExistingPuzzle);