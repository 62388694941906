import React from "react";

import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import AuthService from "../services/auth.service";

class PuzzleSave extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: { username: "" },
            puzzleName: '',
            isLoading: false,
            isSaved: false
        };
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();

        if (currentUser) {
            this.setState({ currentUser: currentUser, userReady: true });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.currentUser.accessToken) {
            this.setState({ isLoading: true });

            const response = await fetch('/api/user/saveImage', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': this.state.currentUser.accessToken
                },
                body: JSON.stringify({
                    path: this.props.puzzleSavePath,
                    puzzleName: this.state.puzzleName,
                    prompt: this.props.puzzlePrompt
                }),
            });

            this.setState({isLoading: false, isSaved: true});
            this.props.saveCompleteCallback();
    
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    render() {
        return (
            <Alert variant="info">
                <h3>Share your puzzle with the world?</h3>
                <Container>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formPuzzleName">
                            <Form.Label><b>Name your puzzle</b></Form.Label>
                            <Form.Control type="text" name="puzzleName" placeholder="My fun puzzle" onChange={this.handleChange} />
                        </Form.Group>
                        <Button
                            className="btn btn-primary btn-block"
                            disabled={this.state.isLoading}
                            type="submit"
                        >
                            {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Share</span>
                        </Button>
                    </Form>
                </Container>
            </Alert>
        )
    };
}

export default PuzzleSave;