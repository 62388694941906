import React from "react";

import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import Form from 'react-bootstrap/Form';
import PuzzleField from "./PuzzleField";

import AuthService from "./services/auth.service";
import Alert from 'react-bootstrap/Alert';


class PuzzleForm extends React.Component {
    puzzleStyles = {
        'None': '',
        'Brutalist': 'Brutalist',
        'Cartoon': 'Cartoon',
        'Cubist': 'Cubist',
        'Impressionist': 'Impressionist',
        'Picasso': 'Picasso',
        'Realist': 'Realist',
        'Sketch': 'Sketch',
    }

    constructor(props) {
        super(props);
        this.state = { prompt: '', isLoading: false, imageSize: "large", puzzleSize: 'Small', puzzleStyle: 'None', currentUser: { username: "" } };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.child = React.createRef();
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    componentDidMount() {
        const currentUser = AuthService.getCurrentUser();

        if (currentUser) {
            this.setState({ currentUser: currentUser, userReady: true });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        let appendStyle = ' ' + this.puzzleStyles[this.state.puzzleStyle];

        this.setState({ isLoading: true });

        this.child.current.startLoading();
        const response = await fetch('/api/generateimage', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': this.state.currentUser.accessToken ? this.state.currentUser.accessToken : ''
            },
            body: JSON.stringify({
                prompt: this.state.prompt + appendStyle,
                size: this.state.imageSize,
            }),
        });

        const data = await response.json();
        const imageUrl = data.data;
        const errorMessage = data.error;
        this.child.current.initializeNewPuzzle({ imageUrl: imageUrl, puzzleSize: this.state.puzzleSize, errorMessage: errorMessage, noShare: false, prompt: this.state.prompt + appendStyle });

        this.setState({ isLoading: false });
    }

    render() {
        return (
            <Container className="p-5 mb-4 bg-light rounded-3">
                <h1 className="header">Create a new puzzle</h1>
                <Container>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group className="mb-3" controlId="formAIPrompt">
                            <Form.Label>Describe your image</Form.Label>
                            <Form.Control type="text" name="prompt" placeholder="A Happy Cat" onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPuzzleSize">
                            <Form.Label>Puzzle Size</Form.Label>
                            <Form.Select name="puzzleSize" onChange={this.handleChange}>
                                <option>Small</option>
                                <option>Medium</option>
                                <option>Large</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPuzzleStyle">
                            <Form.Label>Image Style (optional)</Form.Label>
                            <Form.Select name="puzzleStyle" onChange={this.handleChange}>
                                <option>None</option>
                                <option>Brutalist</option>
                                <option>Cartoon</option>
                                <option>Cubist</option>
                                <option>Impressionist</option>
                                <option>Picasso</option>
                                <option>Realist</option>
                                <option>Sketch</option>
                            </Form.Select>
                        </Form.Group>
                        <Alert variant="warning" style={{ display: !this.state.currentUser.accessToken ? 'block' : 'none' }}>
                            <b>Warning: </b> You are not currently logged in, and you won't be able to save or share puzzles you create.
                        </Alert>
                        <Button variant="primary" type="submit" disabled={this.state.isLoading}>
                            Generate Puzzle

                            {this.state.loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                        </Button>
                    </Form>
                    <PuzzleField ref={this.child} />
                </Container>
            </Container>
        )
    };
}

export default PuzzleForm;