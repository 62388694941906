import React from "react";

import Button from 'react-bootstrap/Button';
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Dna} from 'react-loader-spinner';
import Alert from 'react-bootstrap/Alert';
import PuzzleSave from './components/puzzle-save.component';

import AuthService from "./services/auth.service";

import "./PuzzleField.css";
import { Canvas, painters } from 'headbreaker';

const headbreaker = require('headbreaker');



class PuzzleField extends React.Component {
    puzzleSizes = {
        "Small": {x: 4, y: 4},
        "Medium": {x: 6, y: 6},
        "Large": {x: 8, y: 8},
    }

    constructor(props) {
        super();
        this.state = {
            loading: false, 
            active:false, 
            activeCanvas: null,
            isError: false,
            errorMessage: '',
            isSuccess: false,
            currentPuzzleImage: '',
            currentUser: { username: "" },
            shareComplete: false,
            noShare: false,
            initialWidth: 0,
            puzzlePrompt: ''
        };

        this.puzzleFieldContainer = React.createRef();
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://flbulgarelli.github.io/headbreaker/js/headbreaker.js";
        script.async = true;

        const currentUser = AuthService.getCurrentUser();

        if (currentUser) {
            this.setState({ currentUser: currentUser, userReady: true });
        }
      
        document.body.appendChild(script);

        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    startLoading() {
        this.setState({
            loading: true,
            active: false,
            activeCanvas: null,
            isError: false,
            errorMessage: '',
            currentPuzzleImage: '',
            shareComplete: false
        });
    }

    finishLoading() {
        this.setState({
            loading: false,
            active: true,
            isError: false,
            errorMessage: '',
            isSuccess: false,
            currentPuzzleImage: '',
            shareComplete: false
        });
    }

    flagSuccess() {
        console.log("Puzzle finished");
        this.setState({
            isSuccess: true
        })
    }

    flagError(message) {
        this.finishLoading();
        this.setState({
            isError: true,
            errorMessage: message,
            currentPuzzleImage: ''
        })
    }

    updateDimensions = () => {
        if(this.state.activeCanvas) {
            const container = document.getElementById('puzzle-field');
            this.state.activeCanvas.resize(container.offsetWidth, container.scrollHeight);
            this.state.activeCanvas.scale(container.offsetWidth / this.state.initialWidth);
            this.state.activeCanvas.draw();
        }
    };

    initializeNewPuzzle(props) {
        if(props.errorMessage) {
            this.flagError(props.errorMessage);
        } else {
            let puzzle = new Image();
            puzzle.src = props.imageUrl;
            let me = this;

            const container = document.getElementById('puzzle-field');
            this.setState({initialWidth: 1200});
            this.setState({puzzlePrompt: props.prompt});

            puzzle.onload = () => {
            this.finishLoading();
            const canvas = new headbreaker.Canvas('puzzle-field', {
                //width: this.puzzleFieldContainer.current.offsetWidth, 
                width: this.state.initialWidth,
                height: 600, 
                image: puzzle,
                outline: new headbreaker.outline.Rounded(),
                pieceSize: 70, proximity: 18,
                borderFill: 0, strokeWidth: 1.25,
                lineSoftness: 0.18,
                preventOffstageDrag: true,
                fixed: true,
                maxPiecesCount: {x: 10, y: 10},
                painter: new painters.Konva()
            });
            canvas.adjustImagesToPuzzleWidth();

            canvas.autogenerate({
                horizontalPiecesCount: this.puzzleSizes[props.puzzleSize].x,
                verticalPiecesCount: this.puzzleSizes[props.puzzleSize].y,
            });

            canvas.shuffle(0.7);

            canvas.resize(container.offsetWidth, container.scrollHeight);
            canvas.scale(container.offsetWidth / this.state.initialWidth);



            canvas.attachSolvedValidator();
            canvas.onValid(() => {
                this.flagSuccess();
            });


            canvas.draw();
            me.setState({activeCanvas: canvas, currentPuzzleImage: props.imageUrl, noShare: props.noShare });
            
            }
        }
    }

    handleSharePuzzle = async (event) => {
        event.preventDefault();
    }

    reset = (event) => {
        this.state.activeCanvas.shuffle(0.8);
        this.state.activeCanvas.draw();
    }

    solve = (event) => {
        this.state.activeCanvas.solve();
        this.state.activeCanvas.draw();
    }

    saveCompleteCallback = () => {
        this.setState({ shareComplete: true});
    }


    render() {
        return (
            <Container id="puzzle-wrapper" className="mt-3 mb-3">
                <Container id="spinner" style={{display: this.state.loading ? 'block' : 'none'}}>
                    <Dna color="#00BFFF" height={80} width={80} display/>
                </Container>
                <Alert variant="success" style={{display: this.state.isSuccess && !this.state.loading ? 'block' : 'none'}}>
                    You did it! Congratulations!
                </Alert>
                <Alert variant="info" style={{display: this.state.shareComplete ? 'block' : 'none'}}>
                    Puzzle Shared!
                </Alert>
                <Container style={{display: this.state.isSuccess 
                    && this.state.currentUser.accessToken 
                    && !this.state.shareComplete 
                    && !this.state.loading 
                    && !this.state.noShare
                    ? 'block' : 'none'}} >
                    <PuzzleSave 
                        puzzleSavePath = { this.state.currentPuzzleImage }
                        puzzlePrompt = { this.state.puzzlePrompt }
                        saveCompleteCallback = { this.saveCompleteCallback } />
                </Container>
                <Alert variant="danger" style={{display: this.state.isError ? 'block' : 'none'}}>
                    {this.state.errorMessage}
                </Alert>
                <Container>

                    <Container id="puzzle-buttons" className="mt-3" style={{display: !this.state.loading && this.state.active ? 'block' : 'none'}}>
                        <Row className="justify-content-md-left">
                            <Col xs lg="1">
                                <Button 
                                    variant="danger"
                                    onClick={this.reset}>
                                    Reset
                                </Button>
                            </Col>
                            <Col xs lg="1">
                                <Button variant="warning"
                                    onClick={this.solve}>
                                    Solve
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    <Container id="puzzle-field" ref={this.puzzleFieldContainer} className="puzzle-pad"/>   
                </Container>
            </Container>
        );
    };
}

export default PuzzleField;